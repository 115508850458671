<template>
	<div>
		<!-- <v-btn
			@click="sendMail()"
		>
			enviar
		</v-btn> -->
	  <v-row>
		<v-col lg="7">
		<s-crud
			title="PROYECTOS"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			ref="gridtaskProyect"
			@rowSelected="rowSelected($event)" 
			searchInput
			:height="400"
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<!-- <v-col>
							<s-date
								label="Fecha Inicio" 
								v-model="filter.DateBegin" 
							></s-date>
						</v-col>
						<v-col>
							<s-date
								label="Fecha Fin" 
								v-model="filter.DateEnd" 
							></s-date>
						</v-col> -->
						<v-col sm="4">
							<s-select-definition
								label="Estado"
								:def="1393"
								v-model="filter.TkpStatus"
								clearable
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template scope="props">
				<v-container>
					<v-row justify="center">
						<v-col>
							<s-text
								label="Titulo"
								v-model="props.item.TkpTitle"
							></s-text>
						</v-col>
						<v-col>
							<s-select-definition
								label="Estado"
								:def="1393"
								v-model="props.item.TkpStatus"
								clearable
							></s-select-definition>
						</v-col>
						<v-col>
							<s-textarea
								label="Descripción"
								v-model="props.item.TkpDescription"
							></s-textarea>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:options>
				
			</template>
			<template v-slot:TkpStatus="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TkpStatusColor"
				>
					{{ row.TkpStatusName }}
				</v-chip>
			</template>
		</s-crud>
		</v-col>
		<v-col lg="5">
		<v-row style="margin-top: 2px;">
		<s-crud title="Usuarios" :config="configDetail" @save="saveDetail($event)" ref="gridUsrPermit"
			:filter="filterDetail" remove :height="200"> 
			
			<template v-slot:options>
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" small elevation="0" @click="copyAllow" v-if="$fun.isAdmin()">
                    <v-icon color="default" x-small>
                        fas fa-plus-circle
                    </v-icon>
                    </v-btn>
                </template>
                <span>Asignar Usuarios</span>
                </v-tooltip>
            </template>
		</s-crud>
		<s-crud title="Objetivos" :config="conDetail" @save="saveTarget($event)" ref="gridTarget" add 
			:filter="filterDetail" remove :height="200" > 
			<template scope="props">
				<v-container>
					<v-row justify="center">
						<v-col>
							<s-text
								label="Codigo"
								v-model="TargetCode"
							></s-text>
						</v-col>
						<v-col>
							<s-textarea
								label="Descripción"
								v-model="TargetName"
							></s-textarea>
						</v-col>
					</v-row>
				</v-container>
			</template>
		   </s-crud>
	</v-row>
	</v-col>
	</v-row>

	
	
		<v-dialog
			v-model="dialogPaused"
			width="500"
		>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="dataPaused.TkpReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendPaused()"
							>
								{{selected.TkpPaused == 0 ? "Pausar" : "Reanudar"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelPausedTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
		<v-dialog v-model="showDialogAdd" max-width="500">
      <v-card>
        <v-card-title>
          <label>
            Seleccione a quienes agregará los permisos al proyecto <strong>{{(ProyeSelected.TkpTitle|| "").toUpperCase()}}</strong></label
          >
        </v-card-title>
         
        <v-card-text>
			<v-col>
			<s-select-definition
			label="Seleccionar Nivel de Usuario:"
			:def="1489"
			v-model="nivelUsuario"
			clearable
			></s-select-definition>
			</v-col>
          <s-user v-model="usersCopy" ref="usrcopy" :isCrud="false" :singleRow="false" />
        </v-card-text>

        <v-card-actions>
          <v-btn color="default " small text @click="closeDialogAdd()">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" dark depressed small @click="saveUserPermit()">Asignar Usuarios</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	</div>
</template>

<script>
	import _sTaskService from '@/services/InformationTechnology/TaskProyectService.js';
	import _sTaskPermitService from '@/services/InformationTechnology/TaskProyectPermitSevice.js';
	import _sTaskTargetService from '@/services/InformationTechnology/TaskProyectTargetService.js';
	import sTextarea from '../../../components/Utils/sTextarea.vue';
	import sUser from "@/views/Security/UserOption/User.vue";
import { isUndefined } from 'lodash';

	export default {
  components: { sTextarea ,sUser},
		data() {
			return {
				itemSelected: null,
				filterDetail: { TkpID: 0 },
				dialogEdit: false,
				datatransfer: {},
				dataPaused: {},
				dialogTransfer: false,
				dialogPaused: false,
				selected : {},
				TargetName : null,
				TargetCode:null,
				workerstic: [],
				items:{},
				showDialogAdd: false,
				usersCopy: [],
				ProyeSelected: {},
				nivelUsuario:null,
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
				configDetail: {
					model: {
					TppID: 'ID'
					},
					service: _sTaskPermitService,
					headers: [//{text:'ID',value:'DspID'},
					{ text: 'ID', value: 'TppID' },
					{ text: 'Usuario', value: 'UsrName' }, 
					{ text: 'Nombre', value: 'WorkerName' },
					{ text:'Nivel', value:'NameTypeNivelUsr' }
				],
				},
				conDetail: {
					model: {
					TpoID: 'ID'
					},
					service: _sTaskTargetService,
					headers: [//{text:'ID',value:'DspID'},
					{ text: 'ID', value: 'TpoID' },
					{ text: 'Codigo', value: 'TargetCode' }, 
					{ text: 'Descripcion', value: 'TargetName' }, 
				],
				}
				};
			},
			computed: {

			config() {
			return {

					model: {
						TkpID: "ID",
						Action: "Action",
						TkpStatus: "integer",
						TkpPriority: "integer"
					},
					service: _sTaskService,
					headers: [
						{text: "ID", value: "TkpID"},
						{text: "Titulo", value: "TkpTitle"},
						{text: "Descripción", value: "TkpDescription"},
						{text: "Abierto", value: "TkpDateName"},
						{text: "Estado", value: "TkpStatus"},
					]
				};
			}
		},
		watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
			copyAllow() {
			if (this.ProyeSelected && this.ProyeSelected.TkpID !== undefined) {
				this.showDialogAdd = true;
				this.usersCopy = [];
				this.$refs.usrcopy.refresh();
		    } else {
				this.$fun.alert("No hay proyecto seleccionado, porfavor seleccionar proyecto", "warning");
			}
			},
			closeDialogAdd() {
			this.showDialogAdd = false;
			},
			saveUserPermit() {
				this.usersCopy = Object.assign([], this.usersCopy).filter((x) => x.UsrID != "");
				if(this.usersCopy.length>0){
				if(this.nivelUsuario==null){this.nivelUsuario=2}
				_sTaskPermitService.UsrAllPermit(this.ProyeSelected.TkpID,this.nivelUsuario, this.usersCopy, this.$fun.getUserID()).then((r) => {
					this.$fun.alert("Permisos actualizados correctamente.", "success");
					this.$refs.gridUsrPermit.refresh();
					this.closeDialogAdd();
				});
		     	}else{
					this.$fun.alert("Selecciona usuario a asignar", "info");
				}
			},
			saveTarget(taskTarget){
				this.$fun.alert("¿Seguro de reanudar tarea?", "question")
					.then(r => {
						if(r.value)
						{

							this.items.TpoID = 0;
							this.items.TkpID = this.ProyeSelected.TkpID;
							this.items.TargetName = this.TargetName;
							this.items.TargetCode = this.TargetCode;
							this.items.UsrCreateID = this.$fun.getUserID();
							console.log("itemDatos",taskTarget);
							_sTaskTargetService
							.save(this.items, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200)
								{
									this.$fun.alert("Objetivo agregado correctamente", "success");
									this.$refs.gridTarget.refresh();
						
								}
							})
						}
				});
			},
			sendMail(task)
			{
				_sTaskService
				.sendmail(task, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Enviado correctamente", "success");
						return;
					}
				})
			},

			initialize()
			{
				this.getTicWorkers();
			},

			save(item) {
				if( isUndefined(item.PrsID)) item.PrsID = this.filter.PrsID;
				item.AreID = this.filter.AreID;
				item.UsrID = this.$fun.getUserID();
				if(item.SecStatus == null) item.SecStatus = 1;

				console.log("item save", item);
				 
				item.save();
			},
			getTicWorkers()
			{
				let obj = {
					AreID: this.filter.AreID
				}
				_sTaskService
				.ticworkers(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						console.log('tag', resp.data)
						this.workerstic = resp.data;
					}
				})
			},
			clickActionTask(item)
			{
				if(this.selected.TkpPaused == 1)
				{
					this.$fun.alert("La tarea se encuentra pausada", "info");
					return;
				}

				let message = ''
				if (item.TkpStatus == 1)
				{
					message = '¿Seguro de iniciar?'
					item.TkpStatus = 2;
					item.SecStatus = 1
				}
				else
				{
					message = '¿Seguro de finalizar?'
					item.TkpStatus = 3;
					item.SecStatus = 1
				}

				this.$fun.alert(message, "question")
				.then(r => {
					if(r.value)
					{
						_sTaskService
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								if(item.TkpStatus = 2){
									this.sendMail(item);
								}
								this.$fun.alert("Actualizado correctamente", "success");
								this.$refs.gridtask.refresh();
							}
						})
					}
				})
				
			},

			transferTask()
			{
				console.log('estado ', this.selected.TkpStatus);
				if(this.selected.TkpStatus != 1 && this.selected.TkpStatus != 2)
				{
					this.$fun.alert("La tarea ya fue finalizada o transferida", "info");
					return;
				}
				this.dialogTransfer = true;
			},

			pausedTask()
			{
				if(this.selected.TkpStatus != 1 && this.selected.TkpStatus != 2)
				{
					this.$fun.alert("La tarea ya fue finalizada o transferida", "info");
					return;
				}
				if(this.selected.TkpPaused == 1)
				{
					this.$fun.alert("¿Seguro de reanudar tarea?", "question")
					.then(r => {
						if(r.value)
						{
							this.selected.TkpStatus = 104;
							this.selected.UsrUpdateID = this.$fun.getUserID();
							_sTaskService
							.save(this.selected, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200)
								{
									this.$fun.alert("Reanudada correctamente", "success");
									this.$refs.gridtask.refresh();
									this.dialogPaused = false;
									return;
								}
							})
						}
					})
					return;
				}
				if(this.selected.TkpPaused == 0)
				{
					this.dialogPaused = true;
				}
				
			},

			cancelPausedTask()
			{
				this.dialogPaused = false;
			},

			sendPaused()
			{

				this.selected.TkpStatus = 103;
				this.selected.UsrUpdateID = this.$fun.getUserID();
				this.selected.TkpReasonTraslate = this.dataPaused.TkpReasonTraslate;
				this.$fun.alert("¿Seguro de pausar?", "question")
				.then(r => {
					if(r.value)
					{
						_sTaskService
						.save(this.selected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Pausado correctamente", "success");
								this.$refs.gridtask.refresh();
								this.dialogPaused = false;
								return;
							}
						})
					}
				})
			},

			cancelTransferTask()
			{
				this.dialogTransfer = false;
			},

			sendTransfer()
			{
				if(this.selected.PrsID == this.datatransfer.PrsIDTraslate)
				{
					this.$fun.alert("No se puede trasladar a la misma persona", "info");
					return;
				}
				this.$fun.alert("¿Seguro de transferir?", "question")
				.then(r => {
					if(r.value)
					{
						this.selected.PrsIDTraslate = this.datatransfer.PrsIDTraslate;
						this.selected.TkpReasonTraslate = this.datatransfer.TkpReasonTraslate;
						this.selected.UsrUpdateID = this.$fun.getUserID();
						this.selected.TkpStatus = 102
						
						_sTaskService
						.save(this.selected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Transferido correctamente", "success");
								this.$refs.gridtask.refresh();
								this.dialogTransfer = false;
							}
						})
					}
				})
				
			},

			rowSelected(items)
			{
				if(items.length > 0)
				{
					this.selected = items[0];
					this.filterDetail.TkpID = items[0].TkpID;
					this.itemSelected = items[0];
					this.ProyeSelected=items[0];
				}
			}
		},

		created () {
			this.filter.AreID =  this.$fun.getUserInfo().CtrContract.AreID ?? 0;
			
			this.initialize();
		},
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			console.log( "USER INFO",this.$fun.getUserInfo()); 
		}
	}
</script>

<style scoped>

</style>